import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { apiFactory } from '../../../shared';
import { ChangelogApi, Owner, Request, RequestApi, ResponseError, State, StatesApi, User, Audit, EBusinessType } from '../../../generated-api';
import { showErrorToast, showSuccessToast } from '../../../components/LogisticsToast';
import { FormikErrors, useFormik } from 'formik';
import { logisticsConfirmDialog } from '../../../components/LogisticsConfifmDialog';
import { useNavigate } from 'react-router-dom';
import useRights from '../../../hooks/RightsHook';
const blankError = 'This value should not be blank.';

export default function useRequestEditData(id?: number | null | undefined) {
  const [request, _setRequest] = useState<Request>({});
  const [owner, _setOwner] = useState<Owner>({});
  const [managers, setManagers] = useState<User[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [loading, setLoading] = useState(false);
  const [changelog, setChangelog] = useState<Audit[]>([]);
  const navigate = useNavigate();
  const rights = useRights(security => security.request);

  const requestValidation = useFormik<Request>({
    initialValues: request,
    validate: data => {
      const requestErrors: FormikErrors<Request> = {};
      const requestRequiredFields: Request = {
        user: undefined as any,
      }

      Object.keys(requestRequiredFields).forEach(field => {
        if (!(data as any)[field])
          (requestErrors as any)[field] = blankError;
      });

      return requestErrors;
    },
    onSubmit: () => {},
  });

  const ownerValidation = useFormik<Owner>({
    initialValues: owner,
    validate: data => {
      const ownerErrors: FormikErrors<Owner> = {};

      const ownerRequiredFields: Owner = {
        companyName: undefined as any,
        firstName: undefined as any,
        lastName: undefined as any,
        email: undefined as any,
        phones: undefined as any,
        employerId: undefined as any,
        address: undefined as any,
        city: undefined as any,
        state: undefined as any,
        zip: undefined as any,
        businessType: undefined as any,
      }

      Object.keys(ownerRequiredFields).forEach(field => {
        if (!(data as any)[field])
          (ownerErrors as any)[field] = blankError;
      });

      return ownerErrors;
    },
    onSubmit: () => {},
  });

  const validate = useCallback(async () => {
    requestValidation.handleSubmit();
    const requestErrors = await requestValidation.validateForm(request);
    if (Object.keys(requestErrors).length) {
      throw new Error('Fix errors and try again');
    }

    ownerValidation.handleSubmit();
    const ownerErrors = await ownerValidation.validateForm(owner);
    if (Object.keys(ownerErrors).length) {
      throw new Error('Fix errors and try again');
    }
  }, [request, owner, requestValidation, ownerValidation]);

  const setRequest = useCallback((action: SetStateAction<Request>, validate: boolean = true) => {
    _setRequest(action);
    requestValidation.setValues(action, validate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOwner = useCallback((action: SetStateAction<Owner>, validate: boolean = true) => {
    _setOwner(action);
    ownerValidation.setValues(action, validate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChangelogPromise = (id?: number) => {
    return apiFactory(ChangelogApi)
      .apiChangelogGet({
        entityName: 'Request',
        entityId: id
      });
  }

  useEffect(() => {
    const requestPromise = id
      ? apiFactory(RequestApi).apiRequestsIdGet({ id })
      : Promise.resolve({} as Request);
    const changelogPromise = id
      ? getChangelogPromise(id)
      : Promise.resolve({} as Array<Audit>);
    setLoading(true);
    Promise.all([
      requestPromise,
      apiFactory(RequestApi).apiRequestsManagersGet(),
      apiFactory(StatesApi).apiStatesNamesGet(),
      changelogPromise,
    ])
      .then(([loadedRequest, managers, states, changelog]) => {
        setRequest(loadedRequest, false);
        const loadedOwner = loadedRequest?.owner
          ? {
            ...loadedRequest.owner,
            state: states.find(x => x.id === loadedRequest.owner!.stateId)}
          : {} as Owner;
        setOwner(loadedOwner, false);
        setManagers(managers);
        setStates(states);
        setChangelog(changelog);
        ownerValidation.resetForm({ values: loadedRequest.owner, touched: {}, errors: {}});
        requestValidation.resetForm({ values: loadedRequest, touched: {}, errors: {}});
      })
      .catch(() => showErrorToast('Failed to load data'))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClose = useCallback(() => {
    if (!rights.update && !rights.create) {
      navigate('/hr');
      return;
    }

    if (requestValidation.dirty || ownerValidation.dirty) {
      logisticsConfirmDialog({
        message: 'Save changes?',
        closable: false,
        accept() {
          save().then(() => navigate('/hr'));
        },
        reject() {
          navigate('/hr');
        }
      });
    } else {
      navigate('/hr');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestValidation, request]);

  const save = useCallback(async () => {
    const requestForSave = { ...request, owner} as Request;

    try {
      await validate();
      setLoading(true);

      if (!requestForSave.id) {
        try {
          const existingOwner : Owner = await apiFactory(RequestApi).apiRequestsOwnerEmployerIdGet({ employerId: owner.employerId! });
          if (existingOwner) {
            logisticsConfirmDialog({
              message: 'This company already exists in the database! Use current information?',
              closable: false,
              async accept() {
                setOwner(existingOwner);
                requestForSave.owner = existingOwner;
                await saveLogic(requestForSave);
              },
              reject() {
              }
            });
          }
        } catch(e : ResponseError | any) {
          if ((e as ResponseError)?.response?.status === 404) {
            await saveLogic(requestForSave);
          } else throw e;
        }
      } else {
        await saveLogic(requestForSave);
      }

    } catch (e: any) {
      showErrorToast(e.toString());
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, owner]);

  useEffect(() => {
    if (owner.businessType !== EBusinessType.Llc) {
      setOwner(old => {
        return { ...old, llc: undefined };
      })
    }
  }, [owner.businessType, setOwner])

  const saveLogic = async (requestForSave: Request) => {
    const promise = requestForSave.id
      ? apiFactory(RequestApi)
        .apiRequestsIdPut({ id: requestForSave.id, request: requestForSave })
      : apiFactory(RequestApi)
        .apiRequestsPost({ request: requestForSave });
    const response = await promise;
    showSuccessToast('Request saved');
    if (response) {
      setRequest(response);
      setOwner(response.owner || {});

      var changelog = await getChangelogPromise(response.id);
      setChangelog(changelog);

      ownerValidation.resetForm({ values: response.owner, touched: {}, errors: {} });
      requestValidation.resetForm({ values: response, touched: {}, errors: {} });
    }
  }

  return {
    managers,
    states,
    setRequest,
    request,
    setOwner,
    owner,
    changelog,
    save,
    requestValidation,
    ownerValidation,
    loading,
    handleOnClose,
    rights
  };

}