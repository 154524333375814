import React, { useState } from 'react';
import { EBusinessType, Owner, User } from '../../../generated-api';
import { Card } from 'primereact/card';
import Row from '../../../components/form/Row';
import Column from '../../../components/form/Column';
import Field from '../../../components/form/Field';
import LogisticsCalendar from '../../../components/form/datetime/LogisticsCalendar';
import dayjs from 'dayjs';
import emptyArrowFunction from '../../../shared/util/emptyArrowFunction';
import useRequestEditData from './hook';
import EditableDropdown from '../../../components/form/EditableDropdown';
import StyledInputText from '../../../components/form/StyledInputText';
import Header from '../../../components/form/Header';
import StyledInputMask from '../../../components/form/StyledInputMask';
import LogisticsPhoneInput from '../../../components/LogisticsPhoneInput';
import { Checkbox } from 'primereact/checkbox';
import VisibilityToggler from '../../../components/VisibilityToggler';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import CenteredSpinner from '../../../components/CenteredSpinner';
import ErrorBox from '../../../components/form/ErrorBox';
import RequestUnitList from './units/RequestUnitList';
import RequestDriverList from './drivers/RequestDriverList';
import businessTypes from '../../owners/businessTypes';
import { Panel } from 'primereact/panel';
import ChangelogBlock from '../../../components/ChangelogBlock';
import RequestEditCaptions from './RequestEditCaptions';
import LogisticsFilesUpload from '../../../components/form/files/upload/LogisticsFilesUpload';
import useFilePreview from '../../../components/form/files/preview/filePreviewWidget';
import LogisticsDateOnly from '../../../components/form/datetime/LogisticsDateOnly';
import OwnerDocumentsTable from '../../owner-document/components/OwnerDocumentsTable';
import LogisticsFilePreview from '../../../components/form/files/preview/LogisticsFilePreview';
import LLCType from '../../../data-moqs/LLCType';

function userFullName(user: User | null | undefined) {
  if (!user) return '';

  return `${user.firstName} ${user.lastName}`;
}

const getPhone = (owner: Owner) =>
  owner.phones?.length ? owner.phones[0] : undefined;

const setPhone = (owner: Owner, phone: string) => {
  const phones = owner.phones?.length ? [...owner.phones] : [];
  phones[0] = phone;
  return phones;
}

const StyledCard = styled(Card)`
  & .p-card-body, & .p-card-content {
    height: 100%;
  }

  & .p-card-body {
    overflow-y: auto;
  }
  
  &.p-card {
    height: 90%;
  }
`

function RequestEdit() {
  const { id } = useParams();
  const form = useRequestEditData(id ? parseInt(id) : undefined);
  const [isEditing, setIsEditing] = useState(false);
  const requestHeader = `Request ${form.request.id ? '#' + form.request.id : ''}`;
  const filePreviewWidget = useFilePreview();

  return <>
    <div className='flex pt-2'>
      <div className='flex pl-4'>
        <Button
          icon='pi pi-arrow-left'
          className='p-button-lg p-button-rounded p-button-text p-button-plain'
          onClick={form.handleOnClose}
        />
      </div>
      <div className='flex flex-row-reverse flex-grow-1 pr-4'>
        <Button
          label='CLOSE'
          onClick={form.handleOnClose}
        />
      </div>
    </div>
    <Row className='h-full'>
      <Column className='col-6 h-full my-3'>
        <StyledCard>
          <div className='grid'>
            <div className='col-6'>
              <Header>{requestHeader}</Header>
            </div>
            <div className='col-6 flex flex-row-reverse align-items-start'>
            <VisibilityToggler visible={form.rights.update || form.rights.create}>
              <Button
                label='Edit'
                className='p-button-success pl-4 pr-4'
                onClick={() => setIsEditing(!isEditing)}
              />
            </VisibilityToggler>
            </div>
          </div>
          <Row>
            <Column className='col-4'>
              <Field label={RequestEditCaptions.RingDate}>
                <LogisticsCalendar
                  value={form.request.ringDate}
                  disabled={!isEditing}
                  onChange={newValue => form.setRequest(old => {
                    return {
                      ...old,
                      ringDate: newValue,
                      nextRingDate: dayjs(newValue).add(2, 'day').toDate()
                    }
                  })}
                />
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label={RequestEditCaptions.NextRingDate} required>
                <LogisticsCalendar
                  value={form.request.nextRingDate}
                  onChange={emptyArrowFunction}
                  disabled
                />
                <ErrorBox>{form.requestValidation.errors.nextRingDate}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label={RequestEditCaptions.User} required>
                <EditableDropdown
                  options={form.managers}
                  optionLabel='firstName'
                  renderOption={userFullName}
                  renderSelected={userFullName}
                  renderEmptyOption={() => '-'}
                  renderEmptySelected={() => '-'}
                  value={form.request.user}
                  disabled={!isEditing}
                  onChange={newValue => form.setRequest(old => {
                    return { ...old, user: newValue };
                  })}
                />
                <ErrorBox>{form.requestValidation.errors.user}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column className='col-12'>
              <Field label={RequestEditCaptions.Comment}>
                <StyledInputText
                  // @ts-ignore
                  value={form.request.comment}
                  disabled={!isEditing}
                  onChange={e => form.setRequest(old => {
                    return { ...old, comment: e.target.value };
                  })}
                />
              </Field>
            </Column>
          </Row>
          <Header>Company Info</Header>
          <Row>
            <Column>
              <Field label={RequestEditCaptions['Owner.CompanyName']} required>
                <StyledInputText
                  // @ts-ignore
                  value={form.owner.companyName}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, companyName: e.target.value };
                  })}
                />
                <ErrorBox>{form.ownerValidation.errors.companyName}</ErrorBox>
              </Field>
            </Column>
            <Column>
              <Field label={RequestEditCaptions['Owner.BusinessAs']}>
                <StyledInputText
                  // @ts-ignore
                  value={form.owner.businessAs}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, businessAs: e.target.value };
                  })}
                />
              </Field>
            </Column>
          </Row>
          <Row>
            <Column>
              <Field label={RequestEditCaptions['Owner.FirstName']} required>
                <StyledInputText
                  // @ts-ignore
                  value={form.owner.firstName}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, firstName: e.target.value };
                  })}
                />
                <ErrorBox>{form.ownerValidation.errors.firstName}</ErrorBox>
              </Field>
            </Column>
            <Column >
              <Field label={RequestEditCaptions['Owner.LastName']} required>
                <StyledInputText
                  // @ts-ignore
                  value={form.owner.lastName}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, lastName: e.target.value };
                  })}
                />
                <ErrorBox>{form.ownerValidation.errors.lastName}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.Email']} required>
                <StyledInputText
                  // @ts-ignore
                  value={form.owner.email}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, email: e.target.value };
                  })}
                />
                <ErrorBox>{form.ownerValidation.errors.email}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.Phones']} required>
                <LogisticsPhoneInput
                  value={getPhone(form.owner)}
                  country={'us'}
                  disabled={!isEditing}
                  onChange={number => form.setOwner(old => {
                    return { ...old, phones: setPhone(old, number) };
                  })}
                />
                <ErrorBox>{form.ownerValidation.errors.phones}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.EmployerId']} required>
                <StyledInputMask
                  // @ts-ignore
                  value={form.owner.employerId}
                  mask='99-9999999'
                  placeholder='__-_______'
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, employerId: e.target.value };
                  })}
                />
                <ErrorBox>{form.ownerValidation.errors.employerId}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.Address']} required>
                <StyledInputText
                  // @ts-ignore
                  value={form.owner.address}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, address: e.target.value };
                  })}
                />
                <ErrorBox>{form.ownerValidation.errors.address}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.City']} required>
                <StyledInputText
                  // @ts-ignore
                  value={form.owner.city}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, city: e.target.value };
                  })}
                />
                <ErrorBox>{form.ownerValidation.errors.city}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.State']} required>
                <EditableDropdown
                  options={form.states}
                  optionLabel='name'
                  renderOption={state => state.name}
                  renderSelected={state => state?.name || ''}
                  renderEmptyOption={() => '-'}
                  renderEmptySelected={() => '-'}
                  value={form.owner.state}
                  filter
                  disabled={!isEditing}
                  onChange={newValue => form.setOwner(old => {
                    return { ...old, state: newValue };
                  })}
                />
                <ErrorBox>{form.ownerValidation.errors.state}</ErrorBox>
              </Field>
            </Column>
          </Row>
          <Row>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.Zip']} required>
                <StyledInputText
                  // @ts-ignore
                  value={form.owner.zip}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, zip: e.target.value };
                  })}
                />
                <ErrorBox>{form.ownerValidation.errors.zip}</ErrorBox>
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.Birth']}>
                <LogisticsDateOnly
                  value={form.owner.birth}
                  disabled={!isEditing}
                  onChange={newValue => form.setOwner(old => {
                    return { ...old, birth: newValue };
                  })}
                />
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.EmergencyPhone']}>
                <LogisticsPhoneInput
                  value={form.owner.emergencyPhone}
                  country={'us'}
                  disabled={!isEditing}
                  onChange={number => form.setOwner(old => {
                    return { ...old, emergencyPhone: number };
                  })}
                />
              </Field>
            </Column>
          </Row>
          <Row>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.BusinessType']} required>
                <EditableDropdown
                  options={businessTypes}
                  optionLabel='name'
                  renderOption={x => x.name}
                  renderSelected={x => x?.name || ''}
                  renderEmptySelected={() => '-'}
                  value={businessTypes.find(x => x.id === form.owner.businessType)}
                  disabled={!isEditing}
                  onChange={newValue => form.setOwner(old => {
                    return { ...old, businessType: newValue.id };
                  })}
                />
                <ErrorBox>{form.ownerValidation.errors.businessType}</ErrorBox>
              </Field>
            </Column>
            <VisibilityToggler visible={form.owner.businessType === EBusinessType.Llc}>
              <Column className='col-4'>
                <Field label={RequestEditCaptions['Owner.LLC']}>
                  <EditableDropdown
                    options={LLCType}
                    optionLabel='name'
                    renderOption={x => x.name}
                    renderSelected={x => x?.name || ''}
                    renderEmptySelected={() => '-'}
                    value={LLCType.find(x => x.id === form.owner.llc)}
                    disabled={!isEditing}
                    onChange={newValue => form.setOwner(old => {
                      return { ...old, llc: newValue.id };
                    })}
                  />
                  <ErrorBox>{form.ownerValidation.errors.llc}</ErrorBox>
                </Field>
              </Column>
            </VisibilityToggler>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.TaxExempt']}>
                <Checkbox
                  checked={form.owner.taxExempt}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, taxExempt: e.checked }
                  })}
                />
              </Field>
            </Column>
          </Row>
          <VisibilityToggler visible={form.owner.taxExempt}>
            <Row>
              <Column className='col-4'>
                <Field label={RequestEditCaptions['Owner.ExemptPayeeCode']}>
                  <StyledInputText
                    // @ts-ignore
                    value={form.owner.exemptPayeeCode}
                    disabled={!isEditing}
                    onChange={e => form.setOwner(old => {
                      return { ...old, exemptPayeeCode: e.target.value };
                    })}
                  />
                </Field>
              </Column>
              <Column className='col-4'>
                <Field label={RequestEditCaptions['Owner.FATCA']}>
                  <StyledInputText
                    // @ts-ignore
                    value={form.owner.fatca}
                    disabled={!isEditing}
                    onChange={e => form.setOwner(old => {
                      return { ...old, fatca: e.target.value };
                    })}
                  />
                </Field>
              </Column>
            </Row>
          </VisibilityToggler>
          <Header>Direct deposit form</Header>
          <Row>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.DepositCompanyName']}>
                <StyledInputText
                  // @ts-ignore
                  value={form.owner.depositCompanyName}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, depositCompanyName: e.target.value };
                  })}
                />
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.DepositCityStateZip']}>
                <StyledInputText
                  // @ts-ignore
                  value={form.owner.depositCityStateZip}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, depositCityStateZip: e.target.value };
                  })}
                />
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.DepositAddress']}>
                <StyledInputText
                  // @ts-ignore
                  value={form.owner.depositAddress}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, depositAddress: e.target.value };
                  })}
                />
              </Field>
            </Column>
          </Row>
          <Row>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.BlankName']}>
                <StyledInputText
                  // @ts-ignore
                  value={form.owner.blankName}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, blankName: e.target.value };
                  })}
                />
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.AccountingNumber']}>
                <StyledInputText
                  // @ts-ignore
                  value={form.owner.accountingNumber}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, accountingNumber: e.target.value };
                  })}
                />
              </Field>
            </Column>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.RoutingNumber']}>
                <StyledInputText
                  // @ts-ignore
                  value={form.owner.routingNumber}
                  disabled={!isEditing}
                  onChange={e => form.setOwner(old => {
                    return { ...old, routingNumber: e.target.value };
                  })}
                />
              </Field>
            </Column>
          </Row>
          <Header>Documents</Header>
          <Row>
            <Column className='col-12'>
              <OwnerDocumentsTable
                ownerDocuments={form.owner.ownerDocuments}
                filePreviewWidget={filePreviewWidget}
                onChange={form.setOwner}
                disabled={!isEditing || !form.request.id}
              />
            </Column>
          </Row>
          <Row>
            <Column className='col-4'>
              <Field label={RequestEditCaptions['Owner.VoidCheck']}>
                <LogisticsFilesUpload
                  value={form.owner.voidCheck}
                  disabled={!isEditing}
                  filePreviewWidget={filePreviewWidget}
                  onChange={newFiles => form.setOwner(old => {
                    return { ...old, voidCheck: newFiles };
                  })}
                />
              </Field>
            </Column>
            <Column className='col-4'>
              <Field 
                labelClassName='opacity-100'
                label={<>
                  <span className='opacity-60'>{RequestEditCaptions['Owner.Contract']}</span>
                  <span className={`${form.owner?.signature ? 'sign-green-color' : 'sign-red-color'} opacity-100`}> (signed)</span>
              </>}>
                <LogisticsFilesUpload
                  value={form.owner.contract}
                  disabled={!isEditing}
                  filePreviewWidget={filePreviewWidget}
                  onChange={newFiles => form.setOwner(old => {
                    return { ...old, contract: newFiles };
                  })}
                />
              </Field>
            </Column>
          </Row>
          <footer className='flex justify-content-end w-full gap-3 pb-3'>
            <VisibilityToggler visible={form.rights.update || form.rights.create}>
              <Button
                label='Save'
                className='p-button-success pl-4 pr-4'
                onClick={form.save}
              />
            </VisibilityToggler>
          </footer>
          <CenteredSpinner visible={form.loading} />
        </StyledCard>
      </Column>
      <Column className='col-6 my-3 overflow-y-auto' style={{height: '90%'}}>
        <Card>
          <Header>Units</Header>
          <RequestUnitList
            request={form.request}
            setRequest={form.setRequest}
            vehicles={form.request.vehicles}
            setVehicle={(updatedVehicle, forDeletion) => form.setRequest(old => {
              if (!updatedVehicle) return old;

              let oldVehicles = [...(old.vehicles || [])];
              const vehiclesWithoutUpdatedVehicle = oldVehicles
                .filter(v => v.id && v.id !== updatedVehicle.id);
              const newVehicles = forDeletion ? vehiclesWithoutUpdatedVehicle : vehiclesWithoutUpdatedVehicle.concat(updatedVehicle);
              return {
                ...old,
                vehicles: newVehicles,
              }
            })}
          />
        </Card>
        <Card className='my-3'>
          <Header>Drivers</Header>
          <RequestDriverList
            request={form.request}
            drivers={form.request.drivers}
            setDriver={updatedDriver => form.setRequest(old => {
              if (!updatedDriver) return old;

              let newDrivers = [...(old.drivers || [])];
              return {
                ...old,
                drivers: newDrivers
                  .filter(d => d.id && d.id !== updatedDriver.id)
                  .concat(updatedDriver),
              }
            })}
          />
        </Card>
        <div className="col-12 p-0">
          <Panel header='History of changes' toggleable>
            <ChangelogBlock
              changelog={form.changelog}
              formCaptions={RequestEditCaptions}
              enums={[{ name: 'EBusinessType', enum: businessTypes }]}
            />
          </Panel>
        </div>
        </Column>
    </Row>
    <LogisticsFilePreview
      filePreviewWidget={filePreviewWidget}
    />
  </>
}

export default RequestEdit;